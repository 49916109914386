<template>
    <v-card>
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                Add New Delivery
            </h2>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text class="px-5">
            <v-row no-gutters>
                <v-col cols="12" class="mt-6 mb-3">
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        hide-details
                        class="elevation-0"
                        :mobile-breakpoint="0"
                        :loading="loading"
                        disable-pagination
                        hide-default-footer
                    >
                        <template v-slot:[`item.check`]="{ item }">
                            <div class="d-flex justify-center">
                                <v-checkbox
                                    color="primary"
                                    v-model="item.check"
                                />
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-form v-model="valid" class="mb-5">
                <v-row no-gutters>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="code"
                            type="password"
                            hide-details
                            label="User Code"
                            :rules="[rules.required]"
                            required
                            class="px-2"
                        />
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="confirmCode"
                            type="password"
                            hide-details
                            label="Confirm Code"
                            :rules="[rules.required, matchCode]"
                            required
                            class="px-2"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-textarea
                            v-model="deliveryNotes"
                            label="Notes"
                            rows="1"
                            hide-details
                            class="px-2"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                :loading="loading"
                rounded
                color="primary"
                class="mb-4 mt-n4"
                @click="addNewDelivery"
                :disabled="validateItems || !valid"
            >
                ADD NEW DELIVERY
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
const CryptoJS = require('crypto-js')

export default {
    name: 'AddNewDelivery',
    props: {
        bom: {
            type: Object,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        requestData: {
            type: Object,
            required: false,
        },
    },
    data: () => ({
        valid: false,
        confirmCode: undefined,
        code: undefined,
        deliveryNotes: '',
        loading: false,
        userCode: undefined,
        updatedItems: [],
        headers: [
            {
                text: 'NAME',
                value: 'description',
                sortable: false,
                align: 'left',
            },
            {
                text: 'QTY',
                value: 'quantityToDeliver',
                sortable: false,
                align: 'center',
            },
            {
                text: 'CHECK',
                value: 'check',
                sortable: false,
                align: 'center',
            },
        ],
        rules: {
            required: v => !!v || 'The value is required',
        },
    }),
    computed: {
        validateItems() {
            for (const item of this.items) {
                if (
                    !item.quantityToDeliver ||
                    item.quantityToDeliver <= 0 ||
                    !item.check
                ) {
                    return true
                }
            }
            return false
        },
    },
    inject: {
        addDeliveries: {
            from: 'addDeliveries',
            default: () => null,
        },
        removePartialDelivery: {
            from: 'removePartialDelivery',
            default: () => null,
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        matchCode() {
            return !!(
                this.code &&
                this.confirmCode &&
                this.code === this.confirmCode
            )
        },
        async addNewDelivery() {
            try {
                this.loading = true
                if (this.code) {
                    const encryptedCode = CryptoJS.AES.encrypt(
                        this.code,
                        process.env.VUE_APP_ENCRYPTION_PHRASE
                    ).toString()
                    this.code = encryptedCode
                    this.confirmCode = this.code
                }
                this.updatedItems = await API.sendBOM({
                    workOrderId: this.bom.workOrderId,
                    bomId: this.bom.id,
                    partials: this.items.map(item => ({
                        id: item.id,
                        ...(item.partialDeliveryId
                            ? { partialDeliveryId: item.partialDeliveryId }
                            : {}),
                        quantity: Number(item.quantityToDeliver),
                        notes: this.deliveryNotes,
                    })),
                    signCode: this.code,
                    notificationId: 'wVsO3yO00Q4kovYLZlxJ',
                    requestId: this.requestData.id,
                    requestedOn: this.requestData.createdOn,
                    requestedFor: this.requestData.deliverDate,
                })
                // remove partial delivery
                for (const item of this.items) {
                    this.removePartialDelivery(item)
                }
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.addDeliveries(this.updatedItems)
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
